import React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/theme';
import {ProfileProvider} from "./contexts/profile";
import {BrowserRouter} from "react-router";
import {ROOT_URL} from "./config/url";
import {Screen} from "./screens/Screen";
import eruda from "eruda";


function App() {
  if (process.env.REACT_APP_DEV_MODE === 'true') {
    eruda.init();
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline/>
      <ProfileProvider rootUrl={ROOT_URL}>
        <BrowserRouter>
          <Screen/>
        </BrowserRouter>
      </ProfileProvider>
    </ThemeProvider>
  );
}

export default App;
