import React from 'react';
import { Box, Typography, LinearProgress } from '@mui/material';
import Robot from '../icons/robot.png'

export const LoaderScreen = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'linear-gradient(to bottom, #FFFCF7, #EAF7FF)',
        textAlign: 'center',
      }}
    >
      {/* Иконка Worky */}
      <img
        src={Robot}
        alt="Worky Robot"
        style={{
          width: '150px',
          position: 'absolute',
          right: 0,
          top: 20,
          animation: 'floating 2s infinite ease-in-out',
        }}
      />

      {/* Текст Worky */}
      <Typography
        variant="h4"
        sx={{ fontWeight: 700, marginBottom: '8px', color: '#333' }}
      >
        Worky
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ color: '#555', marginBottom: '30px' }}
      >
        Private AI-Telegram secretary
      </Typography>

      {/* Лоадер */}
      <Box sx={{ width: '80%', maxWidth: 400 }}>
        <LinearProgress
          variant="determinate"
          value={56} // Пример значения
          sx={{
            height: 10,
            borderRadius: 5,
            backgroundColor: '#F0F0F0',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#FF6B00',
            },
          }}
        />
      </Box>

      {/* Подпись загрузки */}
      <Typography
        variant="body2"
        sx={{ marginTop: '10px', color: '#666' }}
      >
        Загружаем вашу продуктивность 56%
      </Typography>
    </Box>
  );
}
