import React from 'react'
import styleIcon from './Icon.module.scss'

export const IconView = (props) => {
  const { size, view } = props

  return (
    !!view && (
      <div
        className={styleIcon._}
        style={{
          width: size,
          height: size
        }}
      >
        {view}
      </div>
    )
  )
}
