import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#FF6B00' },
    success: { main: '#31C859' },
    secondary: { main: '#5E90F2' },
    // background: { default: '#FFFCF7' },
  },
  shape: {
    // borderRadius: 16, // Общая настройка для всех компонентов
  },
  typography: {
    // fontFamily: 'Arial, sans-serif',
    // fontSize: 12,
  },
});

export default theme;
