import {useContext, useEffect, useState} from 'react'
import {ProfileContext} from "../contexts/profile";

export const useProfile = () => {
  const {profileState, profileDispatch} = useContext(ProfileContext)
  const [profile, setProfile] = useState(profileState.profileData)
  const [auth, setAuth] = useState(profileState.telegramAuth)
  const [initData, setInitData] = useState(profileState.telegramInitData)

  useEffect(() => {
    setProfile(profileState.profileData)
  }, [profileState.profileData])

  useEffect(() => {
    setAuth(profileState.telegramAuth)
  }, [profileState.telegramAuth])

  useEffect(() => {
    setInitData(profileState.telegramInitData)
  }, [profileState.telegramInitData])

  return {
    profile,
    auth,
    initData
  }
}
