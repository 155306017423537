import React, {useEffect, useState} from "react";
import {Checkbox, Divider} from "@mui/material";
import {ROOT_URL} from "../../../config/url";
import moment from "moment";
import {useProfile} from "../../../hooks/useProfile";
import axios from "axios";
import {TaskAddModal} from "./TaskAddModal";
import {TaskEditModal} from "./TaskEditModal";
import {Link} from "react-router";
import {Icon} from "../../../components/Icon";

export const TaskList = () => {
  const {profile, auth, initData} = useProfile();

  const [tasks, setTasks] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);

  useEffect(() => {
    axios.get(
      '/api/v1/tasks/day',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks(response.data.data);
    });
  }, [isUpdate]);

  const handleCheckboxChange = (taskUuid) => {
    axios.put(
      '/api/v1/task/' + taskUuid + '/checked', {},
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks((prevTasks) =>
        [...prevTasks.map((task) => {
          if (task.uuid === taskUuid) {
            task.checked_at = response.data.data.checked_at;
          }
          return task;
        })]
      );
    });
  };

  // TODO Сюда бы еще сортировку по времени сделать.
  const editTask = (object) => {
    setTasks((prevTasks) =>
      [...prevTasks.map((task) => {
        if (task.uuid === object.uuid) {
          task = object;
        }
        return task;
      })]
    );
  }

  return (
    <>
      <div
        style={{
          padding: 16,
          backgroundColor: "white",
          boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
          borderRadius: 11,
          height: "100%",
          width: "calc(68% - 16px)",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
          marginRight: 16
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          height: 24
        }}>
          <Link to={"/calendar-task"} style={{
            textDecoration: "none"
          }}>
            <span style={{
              color: '#1982F4',
              fontSize: 16,
              fontWeight: 400
            }}>
              Календарь
            </span>
          </Link>
          <div style={{
            display: "flex",
            justifyContent: "end"
          }}>
            <div style={{
              cursor: "pointer",
              marginRight: 4,
              height: 24
            }}
                 onClick={() => setIsModalAdd(true)}>
              <Icon name={'icon-add-circle'} size={24} color={'#A4A4A4'}/>
            </div>

            <div style={{
              cursor: "pointer",
              height: 24
            }}>
              <Link to={"/calendar-task"}>
                <Icon name={'icon-calendar'} size={24}/>
              </Link>
            </div>
          </div>
        </div>

        <div style={{
          margin: "8px 0",
          backgroundColor: "#c4c4c4",
          height: 1
        }}/>

        <div style={{
          overflowY: "auto",
          height: "100%",
        }}>
          {!!tasks && tasks.map((task) => (
            <div
              style={{
                overflow: 'hidden',
                boxSizing: 'border-box',
                display: "flex",
                flexDirection: "row",
                flexWrap: "nowrap",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
              key={task.uuid}
            >
              <Checkbox
                checked={!!task.checked_at}
                color={"success"}
                onChange={() => handleCheckboxChange(task.uuid)}
                style={{
                  padding: "2px 0",
                  marginRight: 4
                }}
              />
              <span
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: 14,
                }}
                onClick={() => setModalEdit(task)}
              >
                {!!task.time && (
                  <span style={{
                    marginRight: 4,
                    fontWeight: 700
                  }}>{task.time}</span>
                )}{task.title}
              </span>
            </div>
          ))}
        </div>
      </div>

      <TaskAddModal
        open={isModalAdd}
        successfulResponse={() => setIsUpdate(!isUpdate)}
        handleClose={() => setIsModalAdd(false)}
      />
      <TaskEditModal
        open={!!modalEdit}
        handleClose={() => setModalEdit(null)}
        object={modalEdit}
        // successfulResponse={object => editTask(object)}
        successfulResponse={() => setIsUpdate(!isUpdate)}
      />
    </>
  );
}
