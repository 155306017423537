import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import {Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import {Link} from "react-router";
import {useProfile} from "../../hooks/useProfile";
import {ROOT_URL} from "../../config/url";
import {TaskAddModal} from "../Dashborad/components/TaskAddModal";
import {TaskEditModal} from "../Dashborad/components/TaskEditModal";
import {Icon} from "../../components/Icon";

function CustomCalendar({selectedDate, onDateChange, taskCounts}) {
  const startOfMonth = moment(selectedDate).startOf('month');
  const endOfMonth = moment(selectedDate).endOf('month');
  const daysInMonth = endOfMonth.date();

  // Преобразуем `day()` для раскладки с понедельника: 0 = понедельник, ..., 6 = воскресенье
  const getCorrectedDay = (day) => (day === 0 ? 6 : day - 1);

  const startDayOfWeek = getCorrectedDay(startOfMonth.day());
  const endDayOfWeek = getCorrectedDay(endOfMonth.day());

  const weeks = [];
  let currentWeek = [];

  // Заполняем первую неделю оставшимися днями предыдущего месяца
  if (startDayOfWeek !== 0) {
    const prevMonthEnd = moment(startOfMonth).subtract(1, 'day');
    for (let i = startDayOfWeek - 1; i >= 0; i--) {
      currentWeek.push(moment(prevMonthEnd).subtract(i, 'days'));
    }
  }

  for (let day = 1; day <= daysInMonth; day++) {
    currentWeek.push(moment(startOfMonth).date(day));
    if (currentWeek.length === 7 || day === daysInMonth) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  }

  // Заполняем последнюю неделю днями следующего месяца
  if (endDayOfWeek !== 6) {
    for (let i = 1; i <= 6 - endDayOfWeek; i++) {
      currentWeek.push(moment(endOfMonth).add(i, 'days'));
    }
    weeks.push(currentWeek);
  }

  const isCurrentDay = (day) => day.isSame(moment(), 'day');
  const isCurrentMonth = (day) => day.isSame(selectedDate, 'month');

  const monthText = (month) => {
    const months = [
      'январь', 'февраль', 'март', 'апрель', 'май', 'июнь',
      'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь',
    ];
    return months[Number(month) - 1].charAt(0).toUpperCase() + months[Number(month) - 1].slice(1);
  };

  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 4px"
    }}>
      <Box display="flex" justifyContent="space-between" style={{
        marginTop: 2,
        marginBottom: 2,
        alignItems: "center"
      }}>
        <Button onClick={() => onDateChange(moment(selectedDate).subtract(1, 'month'))}>
          <Icon name={'chevron-left'} size={24}/>
        </Button>
        <Typography variant="h6" style={{
          color: "#828282",
          fontSize: 20,
        }}>
          {monthText(moment(selectedDate).format('MM'))} {moment(selectedDate).format('YYYY')}
        </Typography>
        <Button onClick={() => onDateChange(moment(selectedDate).add(1, 'month'))}>
          <Icon name={'chevron-right'} size={24}/>
        </Button>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(7, 1fr)" gap={1}>
        {['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'].map((day) => (
          <Typography key={day} align="center" variant="body2" fontWeight="bold" style={{
            color: "#FF7244",
            fontWeight: 400
          }}>
            {day}
          </Typography>
        ))}
        {weeks.flat().map((day, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            border={day && day.isSame(selectedDate, 'day') ? '2px solid #5E90F2' : isCurrentDay(day) ? '2px solid #FF6B00' : '2px solid white'}
            borderRadius={1}
            p={1}
            onClick={() => onDateChange(day)}
            style={{
              cursor: 'pointer',
              height: 38,
              width: 38,
              color: isCurrentMonth(day) ? 'black' : 'lightgray',
              position: 'relative',
            }}
          >
            <span>{day.date()}</span>

            {!!taskCounts[day.date()] && isCurrentMonth(day) && (
              <div style={{
                position: 'absolute',
                top: -8,
                right: -8,
                color: "white",
                backgroundColor: "#FF6B00",
                height: 17,
                display: "flex",
                padding: "0 5px",
                borderRadius: 24,
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}>
                <span style={{
                  fontSize: 12
                }}>
                  {taskCounts[day.date()]}
                </span>
              </div>
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
}

export const CalendarTask = () => {
  const {profile, auth, initData} = useProfile();

  const [taskCounts, setTaskCounts] = useState([]);
  const [tasks, setTasks] = useState(null);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalAdd, setIsModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(null);

  const [selectedDate, setSelectedDate] = useState(moment());

  const [selectedTask, setSelectedTask] = useState(null);
  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  const currentDateRef = useRef(moment());

  useEffect(() => {
    axios.get(
      '/api/v1/tasks/month',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          year: selectedDate.format("YYYY"),
          month: selectedDate.format("MM")
        }
      }).then((response) => {
      if (!!response.data.data.tasks_by_day) {
        const counts = response.data.data.tasks_by_day.reduce((acc, {day, task_count}) => {
          acc[day] = task_count;
          return acc;
        }, {});
        setTaskCounts(counts);
      } else {
        setTaskCounts([]);
      }
    });
  }, [isUpdate, selectedDate]);

  useEffect(() => {
    axios.get(
      '/api/v1/tasks/day',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: selectedDate.format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks(response.data.data);
    });
  }, [isUpdate, selectedDate]);

  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
  };

  const handleTaskClick = (task) => {
    setSelectedTask(task);
    setActionMenuOpen(true);
  };

  const handleCloseActionMenu = () => {
    setActionMenuOpen(false);
    setSelectedTask(null);
  };

  const handleCheckboxChange = (taskUuid) => {
    axios.put(
      '/api/v1/task/' + taskUuid + '/checked', {},
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setTasks((prevTasks) =>
        [...prevTasks.map((task) => {
          if (task.uuid === taskUuid) {
            task.checked_at = response.data.data.checked_at;
          }
          return task;
        })]
      );
      handleCloseActionMenu();
    });
  };

  const handleDeleteChange = (taskUuid) => {
    axios.delete(
      '/api/v1/task/' + taskUuid + '/delete',
      {
        withCredentials: false,
        baseURL: ROOT_URL,
        headers: {
          'X-Telegram-Auth-Date': auth.auth_date,
          'X-Telegram-Hash': auth.hash,
          'X-Telegram-Init-Data': btoa(initData),
          'User': profile.uuid,
          'Content-Type': 'application/json'
        },
        params: {
          date: moment().format("YYYY-MM-DD")
        }
      }).then((response) => {
      setIsUpdate(!isUpdate);
      handleCloseActionMenu();
    });
  };

  return (
    <>
      <div
        style={{
          padding: 16,
          backgroundColor: "white",
          boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
          borderRadius: 11,
          height: "100%",
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          alignItems: "stretch",
        }}
      >
        <div style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%"
        }}>
          <div style={{
            cursor: "pointer",
            height: 24
          }}>
            <Link to={"/dashboard"}>
              <Icon name={'icon-round-arrow-left'} size={24} color={'#A4A4A4'}/>
            </Link>
          </div>
          <span style={{
            color: '#1982F4',
            fontSize: 16,
            fontWeight: 400,
          }}>
              Календарь
            </span>
          <div style={{
            cursor: "pointer",
            height: 24
          }}
               onClick={() => setIsModalAdd(true)}>
            <Icon name={'icon-add-circle'} size={24} color={'#FF7244'}/>
          </div>
        </div>
        <CustomCalendar
          selectedDate={selectedDate}
          onDateChange={handleDateChange}
          taskCounts={taskCounts}
        />

        <div style={{
          border: currentDateRef.current.format('DD.MM.YYYY') === selectedDate.format('DD.MM.YYYY') ? "2px solid #FF713C" : '2px solid #c4c4c4',
          marginTop: 8,
          marginBottom: 8,
          borderRadius: 7,
          padding: 4,
          fontSize: 14,
          color: currentDateRef.current.format('DD.MM.YYYY') === selectedDate.format('DD.MM.YYYY') ? '#FF713C' : '#202020'
        }}>
          <div style={{
            display: "flex",
            justifyContent: "center"
          }}>
          <span style={{marginRight: 4}}>
            {currentDateRef.current.format('DD.MM.YYYY') === selectedDate.format('DD.MM.YYYY') && 'Сегодня, '}
          </span>
            {selectedDate.format('DD.MM.YYYY')}
          </div>
        </div>

        <div>
          {!!tasks && tasks.map(task => (
            <div key={task.uuid}
                 style={{
                   overflow: 'hidden',
                   boxSizing: 'border-box',
                   display: "flex",
                   flexDirection: "row",
                   flexWrap: "nowrap",
                   alignItems: "flex-start",
                   justifyContent: "flex-start",
                   paddingTop: 4
                 }}
            >
              <Checkbox
                checked={!!task.checked_at}
                color={"success"}
                style={{
                  padding: "2px 0",
                  marginRight: 4
                }}
                onClick={() => handleCheckboxChange(task.uuid)}
              />
              <div style={{
                paddingTop: 3
              }}
                   onClick={() => handleTaskClick(task)}>
                {!!task.time && (
                  <span style={{
                    marginRight: 4,
                    fontWeight: 700
                  }}>{task.time}</span>
                )}{task.title}
              </div>
            </div>
          ))}
        </div>

        {!!selectedTask && (
          <Dialog open={actionMenuOpen} onClose={handleCloseActionMenu} style={{
            borderRadius: 21
          }}>
            <DialogTitle style={{
              fontSize: 16,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "16px 16px 0"
            }}>
              <div style={{
                height: 24,
              }}>{'Действия с задачей'}</div>
              <div style={{
                height: 24,
                cursor: "pointer"
              }}
                   onClick={handleCloseActionMenu}>
                <Icon name={'icon-close-circle'} size={24} color={"#FF7244"}/>
              </div>
            </DialogTitle>
            <DialogContent style={{
              width: 320,
            }}></DialogContent>
            <DialogActions style={{
              display: "flex",
              width: 320,
              padding: "0 16px 16px",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              alignItems: "center",
            }}>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
                   onClick={() => handleCheckboxChange(selectedTask.uuid)}>
                <Icon name={!!selectedTask.checked_at ? 'icon-close-circle' : 'icon-check-circle'} size={48} color={
                  !!selectedTask.checked_at ? '#A4A4A4' : '#A4A4A4'
                }/>
                <div style={{
                  fontSize: 14
                }}>
                  {!!selectedTask.checked_at ? 'Не сделано' : 'Сделано'}
                </div>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
                   onClick={() => handleDeleteChange(selectedTask.uuid)}>
                <Icon name={'icon-archive-down'} size={48}/>
                <div style={{
                  fontSize: 14
                }}>
                  Удалить
                </div>
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
                   onClick={() => setModalEdit(selectedTask)}>
                <Icon name={'icon-eraser'} size={48} color={'#A4A4A4'}/>
                <div style={{
                  fontSize: 14
                }}>
                  Изменить
                </div>
              </div>

              {/*<Button color="secondary"*/}
              {/*        onClick={() => handleCheckboxChange(selectedTask.uuid)}>{!!selectedTask.checked_at ? 'Не сделано' : 'Сделано'}</Button>*/}
              {/*<Button color="secondary" onClick={() => handleDeleteChange(selectedTask.uuid)}>Удалить</Button>*/}
              {/*<Button color="primary" onClick={() => setModalEdit(selectedTask)}>Изменить</Button>*/}
            </DialogActions>
          </Dialog>
        )}
      </div>

      <TaskAddModal
        open={isModalAdd}
        successfulResponse={() => setIsUpdate(!isUpdate)}
        handleClose={() => setIsModalAdd(false)}
      />
      <TaskEditModal
        open={!!modalEdit}
        handleClose={() => setModalEdit(null)}
        object={modalEdit}
        // successfulResponse={object => editTask(object)}
        successfulResponse={() => (setIsUpdate(!isUpdate),
          handleCloseActionMenu())}
      />
    </>
  );
}
