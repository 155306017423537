import React, {useEffect, useState} from 'react'
import {ReactComponent as IconDollar} from './icons/icon-dollar.svg'
import {ReactComponent as IconCalendar} from './icons/icon-calendar.svg'
import {ReactComponent as IconArchiveDown} from './icons/icon-archive-down.svg'
import {ReactComponent as IconAddCircle} from './icons/icon-add-circle.svg'
import {ReactComponent as IconUser} from './icons/icon-user.svg'
import {ReactComponent as IconCheckCircle} from './icons/icon-check-circle.svg'
import {ReactComponent as IconEraser} from './icons/icon-eraser.svg'
import {ReactComponent as IconRoundArrowLeft} from './icons/icon-round-arrow-left.svg'
import {ReactComponent as IconTelegram} from './icons/icon-telegram.svg'
import {ReactComponent as IconLogoWorky} from './icons/logo-worky.svg'
import {ReactComponent as IconCloseCircle} from './icons/icon-close-circle.svg'
import {ReactComponent as IconChevronLeft} from './icons/chevron-left.svg'
import {ReactComponent as IconChevronRight} from './icons/chevron-right.svg'


export const useIcon = (props) => {
  const [view, setView] = useState(null)

  useEffect(() => {
    return () => {
      setView(null)
    }
  }, [])

  useEffect(() => {
    setView(getView(props.name, props.color, props.size))
  }, [props.name, props.color, props.size])

  const getElement = (name) => {
    switch (name) {
      case 'icon-dollar':
        return IconDollar;
      case 'icon-calendar':
        return IconCalendar;
      case 'icon-archive-down':
        return IconArchiveDown;
      case 'icon-add-circle':
        return IconAddCircle;
      case 'icon-user':
        return IconUser;
      case 'icon-check-circle':
        return IconCheckCircle;
      case 'icon-eraser':
        return IconEraser;
      case 'icon-round-arrow-left':
        return IconRoundArrowLeft;
      case 'icon-telegram':
        return IconTelegram;
      case 'logo-worky':
        return IconLogoWorky;
      case 'icon-close-circle':
        return IconCloseCircle;
      case 'chevron-left':
        return IconChevronLeft;
      case 'chevron-right':
        return IconChevronRight;

      default:
        return null
    }
  }

  const getIcon = (Icon, color, width, height) => (
    <Icon fill={color} width={width} height={height}/>
  )

  const getView = (name, color, size) => {
    const width = String(size) + 'px'
    const height = String(size) + 'px'

    return !!getElement(name) && getIcon(getElement(name), color, width, height)
  }

  return {
    size: props.size,
    view
  }
}
