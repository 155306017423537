import React, {useEffect, useState} from "react";
import {Routing} from "./Routing";
import {useProfile} from "../hooks/useProfile";
import {LoaderScreen} from "../components/LoaderScreen";

export const Screen = () => {
  const {profile} = useProfile();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setIsLoading(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {isLoading && <LoaderScreen/>}
      {!isLoading && !profile && (
        <>
          ERROR
        </>
      )}
      {!isLoading && profile && <Routing/>}
    </>
  );
};
