import React from "react";
import {TaskList} from "./components/TaskList";
import Productivity from "../../components/Productivity";

export const Dashboard = () => {
  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "space-between",
        alignItems: "stretch",
        height: 260
      }}>
        <TaskList/>
        <Productivity/>
      </div>
    </>
  );
}
