import React from 'react';
import {useProfile} from "../hooks/useProfile";
import {Icon} from "./Icon";

function Header() {
  const {profile} = useProfile();

  return (
    <header style={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 16
    }}>
      <Icon name={'logo-worky'} size={48}/>
      <div style={{
        display: "flex"
      }}>
          <span style={{marginRight: 4, fontSize: 16, fontWeight: 700}}>
            {!!profile && !!profile.first_name && profile.first_name}
            {!!profile && !profile.first_name && !!profile.telegram_username && profile.telegram_username}
          </span>
        <Icon name={'icon-user'} size={24}/>
      </div>
    </header>
  );
}

export default Header;
