import React from "react";
import {Navigate, Route, Routes} from "react-router";
import {Dashboard} from "./Dashborad/Dashboard";
import {CalendarTask} from "./CalendarTask/CalendarTask";
import Header from "../components/Header";
import {TaskList} from "./Dashborad/components/TaskList";

export const Routing = () => {
  return (
    <div
      style={{
        padding: 16,
        background: "linear-gradient(to bottom, #dff5f9, #d5e4fd)",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-start",
      }}
    >
      <Header/>

      <Routes>
        <Route path="/dashboard" element={<Dashboard/>}/>
        <Route path="/calendar-task" element={<CalendarTask/>}/>

        <Route path="/" element={<Navigate to="/dashboard"/>}/>
      </Routes>
    </div>
  );
};
