import React from "react";
import myImage from './worky.png';

function Productivity() {
  return (
    <>
      {/*<Card sx={{ boxShadow: 3, minWidth: 140, minHeight: 240 }}>*/}
      {/*  <CardContent>*/}
      {/*    /!*<Typography variant="h7" color="secondary">*!/*/}
      {/*    /!*  Worky*!/*/}
      {/*    /!*</Typography>*!/*/}
      {/*    /!*<Divider />*!/*/}
      {/*    /!*<Box mt={2}></Box>*!/*/}
      {/*    <img src={myImage} alt="Card Content" style={{width: 140, height: 240}}/>*/}
      {/*  </CardContent>*/}
      {/*</Card>*/}
      <div style={{
        maxWidth: "32%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 16,
        backgroundColor: "white",
        boxShadow: "0 5px 24px 16px rgba(0, 0, 0, 0.05)",
        borderRadius: 11,
        height: "100%",
        flexDirection: "column",
        flexWrap: "nowrap"
      }}>
        <img src={myImage} alt="Card Content" style={{width: 142, height: 170}}/>
      </div>
    </>
  );
}

export default Productivity;
