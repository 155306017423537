function rootUrl() {
  if (document.location.host === "localhost:7891") {
    return "http://localhost:7890";
  } else {
    // const url = new URL(document.location.href);
    // return url.protocol + "//" + url.hostname;
    return "https://app.workyhelper.org";
  }
}

export const ROOT_URL = rootUrl();
